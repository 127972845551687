/*
porcelain = '#EADCD1';
winterSky = '#A6C8C5';
greyBlue = '#93A4A5';
dustTeal = '#4D6467';
bellPepper = '#D23D15';
*/

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #4d6467;
  background-color: #eadcd1;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
button {
  background-color: #93a4a5;
  border-radius: 18px;
  color: #fff;
  padding: 8px 15px;
  font-size: 1em;
}
button:disabled {
  opacity: 0.6;
}
